<template>
    <div id="goods-box" style="display:flex; ">
        <div>
            <h3>排期管理</h3>
            <el-table :data="paiqiList" :cell-style="{ padding: '2px 0' }" height="634" border stripe>
                <el-table-column prop="yuyue_date" label="时间" align="center" width="180"></el-table-column>
                <el-table-column prop="yuhao" label="余号" align="center" width="180"
                    :show-overflow-tooltip="false"></el-table-column>

                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button @click="toDelPaiqi(scope.row)" type="primary">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="margin-left:50px;">
            <div style="display:flex; justify-content: space-between;align-items: center; ">
                <h3>添加排期</h3> <el-button style="height:38px" @click="addPaiqiOne()" type="primary">添加</el-button>
            </div>
            <el-form ref="form" :model="paiqiInfo" label-width="80px" style="width:500px;">
                <el-form-item label="时间">
                    <el-input v-model="paiqiInfo.yuyue_date"></el-input>
                </el-form-item>
                <el-form-item label="余号">
                    <el-input v-model="paiqiInfo.yuhao"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { getForm, postForm } from '../tools/tool'

export default {
    name: "PaiqiListView",
    data() {
        return {
            goodsId: null,
            paiqiInfo: {
                goods_id: "",
                yuyue_date: "",
                yuhao: "",
            },
            paiqiList: [],
        }
    },

    created() {
        let goodsId = this.$route.query.goodsId;
        if (!goodsId) { return alert("获取医生信息失败!") }
        this.goodsId = goodsId
        console.log("goodsId: ", goodsId);
        this.getPaiqiList(goodsId)
    },
    mounted() { },
    methods: {
        toDelPaiqi(item) {
            console.log("item :", item)
            postForm("/deletepaiqi", { id: item.id, is_show: 2 }).then(res => {
                console.log("res: ", res)
                alert("删除成功! ")
                this.getPaiqiList(this.goodsId)
            })
        },
        addPaiqiOne() {
            this.paiqiInfo.goods_id = this.goodsId
            this.paiqiInfo.is_show = 1
            if (this.paiqiInfo.goodsId == 0 || this.paiqiInfo.yuyue_date == "" || this.paiqiInfo.yuhao == "") {
                return alert("请填写正确参数")
            }
            postForm("/addpaiqi", this.paiqiInfo).then(res => {
                console.log("res: ", res)
                alert("添加排期成功! ")
                this.getPaiqiList(this.goodsId)
            })
        },
        getPaiqiList(goodsId) {
            getForm('/paiqilist', { goods_id: goodsId, is_show: 1 }).then(data => {
                this.paiqiList = data.data
                console.log('paiqiList:', this.paiqiList)
            })
        },
    },
}
</script>

<style lang="less" scoped>
.upPicSty {
    width: 50px;
    height: 50px;
}
</style>